import { graphql } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import SEO from '../components/seo';
import PagePaddingTop from '../components/page-padding-top';
import Box from '../components/box';
import Grid from '../components/grid';
import Card from '../components/card';
import Pill from '../components/pill';
import IntroTextWrapper from '../components/intro-text-wrapper';

export default function FourZeroFour({ data }) {
  return (
    <PagePaddingTop>
      <SEO title="404" />
      <Box
        x={10}
        y={10}
      >
        <div
          className="type--heading-four"
        >
          <p>
            Sorry, the page you’re trying to find doesn’t exist. This might because you’ve entered the web address incorrectly or the page has moved.
          </p>
          <p>
            If you’re interested in exploring our work, we suggest starting with the projects below.
          </p>
        </div>
      </Box>
      <Box x={10} y={10}>
        <Grid
          css={css`
            grid-auto-flow: row dense;
          `}
          feed={true}
        >
          {data.projects.nodes.length > 0 &&
            data.projects.nodes.map((project, i) => (
              <Card
                key={i}
                url={project.comingSoon ? null : `/project/${project.slug.current}`}
                image={project.featuredImage}
                workRatio={project.featuredImage?.asset.metadata.dimensions.aspectRatio}
                monoText={project.discipline
                  .map((discipline) => discipline.title)
                  .sort()
                  .join(', ')}
                text={project.title}
                saveButton={project.id}
                hoverText={project.comingSoon ? <div>Case study coming soon</div> : null}
                css={css`
                  grid-column-start: span 4;

                  @media (max-width: 800px) {
                    grid-column-start: span 6;
                  }

                  @media (max-width: 550px) {
                    grid-column-start: span 8;
                  }

                  img {
                    @media (max-width: 800px) {
                      aspect-ratio: 344/270;
                      object-fit: cover;
                    }

                    @media (max-width: 550px) {
                      aspect-ratio: 344/286;
                    }
                  }
                `}
              />
            ))}
        </Grid>
      </Box>
      <Box
        x={10}
        y={10}
        css={css`
          text-align: center;
        `}
      >
        <Pill
          size={2}
          text="Return Home"
          hover={true}
          background="var(--white)"
          url="/"
        />
      </Box>
    </PagePaddingTop>
  );
}

export const query = graphql`
  query {
    projects: allSanityProject(
      sort: { fields: startDate, order: DESC }
      filter: { hero: { eq: true }, slug: { current: { ne: null } } }
      limit: 6
    ) {
      nodes {
        id
        slug {
          current
        }
        title
        hero
        featuredImage {
          ...Image
          asset {
            description
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
        comingSoon
        discipline {
          title
          slug {
            current
          }
        }
        sector {
          title
          slug {
            current
          }
        }
        client {
          title
          slug {
            current
          }
        }
        region {
          title
          slug {
            current
          }
        }
        status {
          title
          slug {
            current
          }
        }
        size
        startDate
        completionDate
        orderRank
      }
    }
  }
`;
