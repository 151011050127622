import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function PagePaddingTop({ className, children }) {
  return (
    <div
      className={className}
      css={css`
        padding-top: var(--marginPageTop);

        @media (max-width: 800px) {
          padding-top: var(--margin14);
        }

        @media (max-width: 550px) {
          padding-top: var(--margin);
        }

        > div:first-of-type {
          margin-top: 0;
        }
      `}
    >
      {children}
    </div>
  );
}
